import { Container, Nav, Navbar, NavDropdown, Image, Row, Col} from "react-bootstrap"
import { useState } from "react";
import { Link } from "react-router-dom"

function Header(props) {

    const abt =  props.data.aboutData;
    const ser =  props.data.serviceData;

    const [show, setShow] = useState(false);
    const showDropdown = (e) => {
      setShow(!show);
    }
    const hideDropdown = e => {
      setShow(false);
    }

    const [show1, setShow1] = useState(false);
    const showDropdown1 = (e) => {
      setShow1(!show);
    }
    const hideDropdown1 = e => {
      setShow1(false);
    }

    return (
    <>
      <Container fluid className="px-5 d-none d-lg-block bg-dark top-headd">
        <Row className="gx-0">
          <Col md={8} className="text-lg-start mb-2 mb-lg-0">
            <div className="d-inline-flex align-items-center">
              <small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2"></i>{props.data.contactData.address}</small>
              <small className="me-3 text-light"><i className="fa fa-phone-alt me-2"></i>{props.data.contactData.contact}</small>
              <small className="text-light"><i className="fa fa-envelope-open me-2"></i>{props.data.contactData.email}</small>
            </div>
          </Col>
          <Col md={4} className="text-lg-end">
            <div className="d-inline-flex align-items-center">
              <Link className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" to={props.data.contactData.social_twitter}><i className="fab fa-twitter fw-normal"></i></Link>
              <Link className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" to={props.data.contactData.social_facebook}><i className="fab fa-facebook-f fw-normal"></i></Link>
              <Link className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" to={props.data.contactData.social_linkedin	}><i className="fab fa-linkedin-in fw-normal"></i></Link>
              <Link className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" to={props.data.contactData.social_insta}><i className="fab fa-instagram fw-normal"></i></Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Navbar bg="light" collapseOnSelect expand="lg" variant="light" className="main-menu">
        <Container>
          <Navbar.Brand><Link to='/'><Image src="/images/logo-dark.png" alt="Fincon Consultancy" className="main-logo" /></Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" data-toggle="collapse" >
            <Nav className="ms-auto">
              <Link to='/'>Home</Link>
              
              <NavDropdown title="About" show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown} >
                {
                  abt.map((itemm,keyy)=>
                      <NavDropdown.Item key={keyy}><Link to={'/about/'+itemm.slug}>{itemm.title}</Link></NavDropdown.Item>
                  )
                }
              </NavDropdown>
              <NavDropdown title="Services" show={show1} onMouseEnter={showDropdown1} onMouseLeave={hideDropdown1}>
                {
                  ser.map((itemm1,keyy1)=>
                      <NavDropdown.Item key={keyy1}><Link to={'/services/'+itemm1.slug}>{itemm1.title}</Link>
                        {/* <ul>
                        {
                            itemm1.subscervices.map((itemm2,keyy2)=>
                                <li key={keyy2}><Link to={'/service/'+itemm2.slug}>{itemm2.title}</Link></li>
                            )
                        }
                        </ul> */}
                      </NavDropdown.Item>
                  )
                }
              </NavDropdown>

              <Link to='/testimonials'>Testimonials</Link>
              <Link to='/clients'>Clients</Link>
              <Link to='/blogs'>Blog</Link>
              {/* <Link to='/career'>Career</Link> */}
              <Link to='/contact'>Contact</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
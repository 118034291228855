import { Col, Container, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { getApi } from './../utils/request'

import ClientCard from "../components/card/ClientCard";
import { scrollToTop } from '../utils/function'
function Client() {
    
    const [loading, setLoading] = useState(true);
    const [seo, setSeo]         = useState([])
    const [data, setData]       = useState([])

    const getData = async() => {
        try {
            const result = await getApi('client/all');
            const data   = await result.data;
            setData(data.data);

            const result2 = await getApi('default/bytype?type=Client');
            const data2 = await result2.data;
            setSeo(data2.data);
          }
          catch (error) {
          }
    }
    
    useEffect(() => {
        scrollToTop();
        getData();
    }, [])
      
    return (
        <>
            <Container fluid className="bg-header" style={{ backgroundImage: `url(${seo.banner})` }}>
                <Row>
                    <Col md={12} className="pt-lg-5 mt-lg-5 mb-lg-5 pb-lg-5 text-center">
                        <h1 className="display-4 text-white animated zoomIn">Our Clients</h1>
                        <span className="h5 text-white">Commitment . Simplicity . Innovation . Excellence . Proactivity</span>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="py-3">
                <Container className="py-4">
                    <Row className="g-5">
                        {
                            data.map((item, key) =>
                                <Col md={2} sm={6}>
                                    <ClientCard key={key}  item={item} />
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Client

import { Button, Col, Container, Form, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { getApi } from './../utils/request'
import { Link } from "react-router-dom"
import { scrollToTop } from '../utils/function'
function Contact(props) {
    
    const [seo, setSeo]         = useState([])
    
    const getData = async () => {
        try {
            const result2 = await getApi('default/bytype?type=Contact');
            const data2 = await result2.data;
            setSeo(data2.data);
        }
        catch (error) {
        }
    }

    useEffect(() => {
        scrollToTop();
        getData();
    }, [])

    
    return (
        <>
        <Container fluid className="bg-header" style={{ backgroundImage:`url(${seo.banner})` }}>
                <Row>
                    <Col md={12} className="pt-lg-5 mt-lg-5 mb-lg-5 pb-lg-5 text-center">
                        <h1 className="display-4 text-white animated zoomIn">Contact Us</h1>
                        <span className="h5 text-white">We are available 24 x 7</span>
                    </Col>
                </Row>
        </Container>

        <Container fluid className="py-5">
            <Container className="py-3">
                <div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
                    <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
                    <h1 className="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
                </div>
                <Row className="g-5">
                    <Col md={4} sm={12}>
                        <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                            <div className="bg-primary d-flex align-items-center justify-content-center rounded widhgt60">
                                <i className="fa fa-phone-alt text-white"></i>
                            </div>
                            <div className="ps-4">
                                <h6 className="mb-2">Call to ask any question</h6>
                                <h5 className="text-primary mb-0">{props.data.contactData.contact}</h5>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} sm={12}>
                        <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
                            <div className="bg-primary d-flex align-items-center justify-content-center rounded widhgt60">
                                <i className="fa fa-envelope-open text-white"></i>
                            </div>
                            <div className="ps-4">
                                <h6 className="mb-2">Email to get free quote</h6>
                                <h5 className="text-primary mb-0">{props.data.contactData.email}</h5>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} sm={12}>
                        <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
                            <div className="bg-primary d-flex align-items-center justify-content-center rounded widhgt60">
                                <i className="fa fa-map-marker-alt text-white"></i>
                            </div>
                            <div className="ps-4">
                                <h6 className="mb-2">Visit our office</h6>
                                <h5 className="text-primary mb-0">{props.data.contactData.address}</h5>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="g-5 mt-5">
                    <Col md={6} sm={12}>
                        <Form>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Control type="email" placeholder="Full Name" className="bg-light border-0" style={{ height: '55px' }} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Control type="email" placeholder="Email Id" className="bg-light border-0" style={{ height: '55px' }} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Control type="email" placeholder="Contact Number" className="bg-light border-0" style={{ height: '55px' }} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Control type="email" placeholder="City" className="bg-light border-0" style={{ height: '55px' }} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Control type="email" placeholder="Subject" className="bg-light border-0" style={{ height: '55px' }} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Control as="textarea" rows={3} className="bg-light border-0" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="primary" type="submit" className="mt-3">
                                        Send Message
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col md={6} sm={12}>
                        <iframe src={props.data.contactData.map_code}
                         width="100%" height="400" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                </Row>
            </Container>
        </Container>
        </>
    )
}

export default Contact
export const daysAgo = (date) => {
    let last_day = new Date(date);
    let today = new Date();
    let one_day = 1000 * 60 * 60 * 24;
    let days = Math.ceil((today.getTime() - last_day.getTime()) / one_day);
    return days;
};

export const hoursAgo = (date) => {
    let last_time = new Date(date);
    let today = new Date();
    let one_hour = 1000 * 60 * 60;
    let hours = Math.ceil((today.getTime() - last_time.getTime()) / one_hour);
    return hours;
};

export const scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    //document.getElementById("top-id").scrollIntoView();
};

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

export const SeoTitle = (newTitle) => {
    return (document.title = newTitle);
}


import { Card, Col, Container, Row } from "react-bootstrap"
import React from 'react';

function TestimonialListCard(props)
{
    return (
        <Col ms={12} sm={12} className="py-4">
            <Card.Body>
                <div className="d-flex">
                    <div className="testimonial-pic"><img src={props.item.image} className="rounded mr-2" alt="" /></div>
                <blockquote className="blockquote mb-0 ml-2">
                    <p>
                        {' '}
                        {props.item.message}{' '}
                    </p>
                    <footer className="blockquote-footer">
                    {props.item.name} <cite title="Source Title">( {props.item.designation} )</cite>
                    </footer>
                </blockquote>
                </div>
            </Card.Body>
        </Col>
    )
}

export default TestimonialListCard
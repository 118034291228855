import React from "react"

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from "react"

import { getApi } from './utils/request'

import Header from './components/layout/Header'
import Footer from './components/layout/Footer'

import Home from './view/Home';
import About from './view/About';
import Content from './view/Content';
import Services from './view/Services';
import SingleService from './view/SingleService';
import Testimonial from './view/Testimonial';
import Client from './view/Client';
import Career from './view/Career';
import Contact from './view/Contact';
import Blog from './view/Blog';
import SingleBlog from './view/SingleBlog';

import ScrollToTop from './components/ScrollToTop';

function App() {
  
  const [contactData, setConntactData]  =   useState([])
  const [aboutData, setAboutData]       =   useState([])
  const [serviceData, setServiceData]   =   useState([])

  const getData = async () => {
    try {
      const result1 = await getApi('contact/all?domain=finconconsultancy.com');     
      const data1   = await result1.data;
      setConntactData(data1.data);
      
      const result2 = await getApi('about/all');
      const data2   = await result2.data;
      setAboutData(data2.data);
      
      const result3 = await getApi('service/all');
      const data3   = await result3.data;
      setServiceData(data3.data);
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])  
  
  return (
    <BrowserRouter>
          <Header data={{ contactData, aboutData, serviceData }} />
            <Routes>
              <Route path="/" element={<Home data={{ contactData, serviceData }} />}></Route>
              <Route path='/about/:slug' element={<About />}></Route>
              <Route path='/services/:slug' element={<Services />}></Route>
              <Route path='/service/:slug' element={<SingleService />}></Route>
              <Route path='/blog/:slug' element={<SingleBlog />}></Route>
              <Route path='/testimonials' element={<Testimonial />}></Route>
              <Route path='/clients' element={<Client />}></Route>
              <Route path='/career' element={<Career />}></Route>
              <Route path='/contact' element={<Contact  data={{ contactData }} />}></Route>
              <Route path='/blogs/:catid' element={<Blog />}></Route>
              <Route path='/blogs' element={<Blog />}></Route>
              
            </Routes>
          <Footer data={{ contactData, aboutData, serviceData }} />
    </BrowserRouter>
  );
}

export default App;

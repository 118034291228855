import React from 'react';

function TestimonialCard(props)
{
    return (
        <div className="testimonial-item bg-light my-4">
            <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img className="img-fluid rounded widhgt60" src={props.item.image} alt="" />
                <div className="ps-4">
                    <h4 className="text-primary mb-1">{props.item.name}</h4>
                    <small className="text-uppercase">{props.item.designation}</small>
                </div>
            </div>
            <div className="pt-4 pb-5 px-5">{props.item.message}</div>
        </div>
    )
}

export default TestimonialCard
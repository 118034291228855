import { Col, Container, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getApi } from './../utils/request'
import { useParams } from "react-router-dom"
import { scrollToTop } from '../utils/function'
import BlogCard from "../components/card/BlogCard"

function Blog() {

    const [seo, setSeo]             = useState([]);
    const [category, setCategory]   = useState([]);
    const [blogdata, setBlogdata]   = useState([]);

    const catid                     = useParams().catid;
    
    const getData = async (catid) => {
        try {
            const result11 = await getApi('category/all');
            const data11 = await result11.data;
            setCategory(data11.data);

            let result;

            if(catid)
            {
                result = await getApi(`blog/all?start=0&limit=10&catid=${catid}`);
            }
            else
            {
                result = await getApi('blog/all?start=0&limit=10');
            }
            
            const data = await result.data;
            setBlogdata(data.data);
            
            const result2 = await getApi('default/bytype?type=Blog');
            const data2 = await result2.data;
            setSeo(data2.data);
        }
        catch (error) {
        }
        
    }

    useEffect(() => {
        scrollToTop();
        getData(catid);
    }, [catid])


    return (
        <>
            <Container fluid className="bg-header" style={{ backgroundImage: `url(${seo.banner})` }}>
                <Row>
                    <Col md={12} className="pt-lg-5 mt-lg-5 mb-lg-5 pb-lg-5 text-center">
                        <h1 className="display-4 text-white animated zoomIn">Blog List</h1>
                        <span className="h5 text-white">Share the Knowledge</span>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="py-5">
                <Container className="py-3">
                    <Row className="g-5">
                        <Col md={8} sm={12}>
                            <Row className="g-5">
                                {
                                    blogdata.map((item, key) =>
                                         <BlogCard key={key} item={item} cardsize="listing-card" />
                                    )
                                }
                            </Row>
                        </Col>
                        <Col md={4} sm={12}>
                            <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                    <h3 className="mb-0">Categories</h3>
                                </div>
                                <div className="link-animated d-flex flex-column justify-content-start sub-servicess">
                                    {
                                        category.map((item, key) =>
                                            <Link key={key} className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" to={`/blogs/${item.id}`}>
                                                <i className="bi bi-arrow-right me-2"></i>{item.title}
                                            </Link>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="mb-5 wow slideInUp right-blogs" data-wow-delay="0.1s">
                                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                    <h3 className="mb-0">Recent Post</h3>
                                </div>
                                {
                                    blogdata.map((item, key) =>
                                         <BlogCard key={key} item={item} cardsize="side-card" />
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Blog

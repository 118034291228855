export const THEME              =   "light";

export const WEB_URL            =   "http://finconconsultancy.com/";
export const API_URL            =   "http://admin.finconconsultancy.com/api/";
export const API_VERSION        =   "v1";
export const MEDIA_PATH         =   "http://admin.finconconsultancy.com/uploads/";

export const APP_NAME           =   "Fincon Consultancy";
export const APP_TITLE          =   "Fincon Consultancy";
export const APP_DESCRIPTION    =   "Fincon Consultancy";

export const APP_ICON           =   "/icon.png";
export const APP_LOGO           =   "/logo-white.png";

import { Col, Container, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getApi } from './../utils/request'
import { scrollToTop } from '../utils/function'

function About() {
    
    const [loading, setLoading] = useState(true);
    const [data, setData]       = useState([])
    const slug                  = useParams().slug;
 
    const getData = async(slug) => {
        try {
            const result = await getApi(`about/byslug?slug=`+slug);
            const data   = await result.data;
            setData(data.data);
          }
          catch (error) {
          }
    }

    useEffect(() => {
        scrollToTop();
        getData(slug);
        setLoading(false);
    }, [slug])
    

    return (
        <>
            <Container fluid className="bg-header" style={{ backgroundImage: `url(${data.banner})` }}>
            {/* `url(${data.banner})`  */}
                <Row>
                    <Col md={12} className="pt-lg-5 mt-lg-5 mb-lg-5 pb-lg-5 text-center">
                        <h1 className="display-4 text-white animated zoomIn">About Us</h1>
                        <span className="h5 text-white">Fincon Consultancy</span>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="py-3 wow fadeInUp" >
                <Container className="py-5">
                    <Row className="g-5">
                        <Col md={12} sm={12}>
                            <div className="section-title position-relative pb-3 mb-3">
                                <h5 className="text-primary text-uppercase">{data.title}</h5>
                                <h1 className="mb-0 text-uppercase">Fincon Consultancy</h1>
                            </div>
                            <p className="mb-4 text-justify">
                            <div dangerouslySetInnerHTML={{__html: data.description}} /> 
                            </p>


                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default About
import { Card, Col, Container, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { getApi } from './../utils/request'

import TestimonialListCard from "../components/card/TestimonialListCard";
import { scrollToTop } from '../utils/function'

function Testimonial() {

    const [loading, setLoading] = useState(true);
    const [seo, setSeo]         = useState([])
    const [data, setData]       = useState([])

    const getData = async () => {
        try {
            const result1   = await getApi('testimonial/all');
            const data1     = await result1.data;
            setData(data1.data);

            const result2   = await getApi('default/bytype?type=Testimonial');
            const data2     = await result2.data;
            setSeo(data2.data);
        }
        catch (error) {
        }
    }

    useEffect(() => {
        getData();
        setLoading(false);
    }, [])

    useEffect(() => {
        scrollToTop();
    }, [seo])

    return (
        <>
            <Container fluid className="bg-header" style={{ backgroundImage: `url(${seo.banner})` }}>
                <Row>
                    <Col md={12} className="pt-lg-5 mt-lg-5 mb-lg-5 pb-lg-5 text-center">
                        <h1 className="display-4 text-white animated zoomIn">Testimonials</h1>
                        <span className="h5 text-white">What our clients think about us</span>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Container>
                    <Row>
                        {
                            data.map((item, key) =>
                                <TestimonialListCard item={item} />
                            )
                        }
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Testimonial

import { Col, Container, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getApi } from './../utils/request'
import CareerCard from "../components/card/CareerCard"

import { scrollToTop } from '../utils/function'

function Career() {
    
    const [seo, setSeo]         = useState([])
    const [data, setData]       = useState([])

    const getData = async () => {
        try {
            const result = await getApi('career/all');
            const data   = await result.data;
            setData(data.data);

            const result2 = await getApi('default/bytype?type=Blog');
            const data2 = await result2.data;
            setSeo(data2.data);
        }
        catch (error) {
        }
      }
    
    useEffect(() => {
        scrollToTop();
        getData();
    }, [])

    useEffect(() => {
        document.title = `${seo.title}`;
    }, [seo])


    return (
        <>
            <article>
                <header className="section-head background-image" style={{ backgroundImage: `url(${seo.banner})` }}>
                    <div className="line">
                        <h1 className="text-white text-s-size-30 text-m-size-40 text-l-size-50 text-size-70 headline">Career with us</h1>
                    </div>
                </header>

                <section className="section">
                    <div className="line">
                        <div className="margin2x">
                            <h2 className="text-extra-strong text-m-size-40 margin-bottom-50" style={{ textAlign: 'center' }}>Check out our latest vacancies and what suits you the best</h2>
                            {
                                data.map((item, key) =>
                                    <CareerCard key={key} item={item} />
                                )
                            }
                        </div>
                    </div>
                </section>


            </article>
        </>
    )
}

export default Career
import { Col, Container, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { getApi } from './../utils/request'
import { useParams } from "react-router-dom"
import { WEB_URL } from './../utils/config'
import { useLocation } from "react-router-dom"

import BlogCard from "../components/card/BlogCard"
import {
    FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon,
    WhatsappIcon, WhatsappShareButton, TelegramIcon, TelegramShareButton, TumblrIcon, TumblrShareButton
} from 'react-share';

import { Image } from 'react-bootstrap';
import { SeoTitle } from './../utils/function'
import { scrollToTop } from '../utils/function'

function SingleBlog() {

    const [data, setData]           =   useState([]);
    const [category, setCategory]   =   useState([]);
    const [blogdata, setBlogdata]   =   useState([]);
    const [seo, setSeo]             =   useState([]);
    const slug                      =   useParams().slug;

    const pathname                  =   useLocation().pathname

    const getData = async (slug) => {
        try {
            const result11 = await getApi('category/all');
            const data11 = await result11.data;
            setCategory(data11.data);

            const result = await getApi('blog/all');
            const data  = await result.data;
            setBlogdata(data.data);
            
            const result12 = await getApi(`blog/byslug?slug=${slug}`);
            const data12   = await result12.data;
            setData(data12.data);

            const result3 = await getApi(`default/bytype?type=Blog`);
            const data3 = await result3.data;
            setSeo(data3.data);
        }
        catch (error) {
        }
    }

    useEffect(() => {
        scrollToTop();
        getData(slug);
        //SeoTitle(data.title)
    }, [slug])

    return (
        <>
            <Container fluid className="bg-header" style={{ backgroundImage: `url(${seo.banner})` }}>
                <Row>
                    <Col md={12} className="pt-lg-5 mt-lg-5 mb-lg-5 pb-lg-5 text-center">
                        <h1 className="display-4 text-white animated zoomIn">Blog List</h1>
                        <span className="h5 text-white">Share the Knowledge</span>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="py-5">
                <Container className="py-3">
                    <Row className="g-5">
                        <Col md={8} sm={12}>
                            <h4 className="mb-3">{data.title}</h4>
                            <div className="d-flex mb-3">
                                <small className="me-3" style={{textTransform:'Capitalize'}}><i className="far fa-user text-primary me-2"></i>{data.author}</small>
                                <small><i className="far fa-calendar-alt text-primary me-2"></i>{data.publishedAt}</small>
                            </div>
                            <img src={data.image} alt="" />
                            <div className="d-flex mb-2 mt-2">
                                {/* <div>View Count</div> */}
                                <div className="mt-3 ms-auto">
                                    <FacebookShareButton url={WEB_URL + pathname} quote={data.title} hashtag="#fincon">
                                        <FacebookIcon size={26} round />
                                    </FacebookShareButton>
                                    &nbsp;
                                    <TwitterShareButton url={WEB_URL + pathname} quote={data.title} hashtag="#fincon">
                                        <TwitterIcon size={26} round />
                                    </TwitterShareButton>
                                    &nbsp;
                                    <LinkedinShareButton url={WEB_URL + pathname} quote={data.title} hashtag="#fincon">
                                        <LinkedinIcon size={26} round />
                                    </LinkedinShareButton>
                                    &nbsp;
                                    <TelegramShareButton url={WEB_URL + pathname} quote={data.title} hashtag="#fincon">
                                        <TelegramIcon size={26} round />
                                    </TelegramShareButton>
                                    &nbsp;
                                    <TumblrShareButton url={WEB_URL + pathname} quote={data.title} hashtag="#fincon">
                                        <TumblrIcon size={26} round />
                                    </TumblrShareButton>
                                    &nbsp;
                                    <WhatsappShareButton url={WEB_URL + pathname} quote={data.title} hashtag="#fincon">
                                        <WhatsappIcon size={26} round />
                                    </WhatsappShareButton>
                                </div>
                            </div>
                            <div className="text-justify mt-4">
                                <p dangerouslySetInnerHTML={{__html: data.description}} />
                            </div>
                        </Col>
                        <Col md={4} sm={12}>
                            <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                    <h3 className="mb-0">Categories</h3>
                                </div>
                                <div className="link-animated d-flex flex-column justify-content-start sub-servicess">
                                    {
                                        category.map((item, key) =>
                                            <Link key={key} className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" to={`/blogs/${item.id}`}>
                                                <i className="bi bi-arrow-right me-2"></i>{item.title}
                                            </Link>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="mb-5 wow slideInUp right-blogs" data-wow-delay="0.1s">
                                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                    <h3 className="mb-0">Recent Post</h3>
                                </div>
                                {
                                    blogdata.map((item, key) =>
                                         <BlogCard key={key} item={item} cardsize="side-card" />
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default SingleBlog

import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Col } from 'react-bootstrap';

function BlogCard(props)
{
    
    return (
        <>
        {props.cardsize === "home-card"?
        
            <Col md={4} sm={12}>
                <div className="blog-item bg-light rounded overflow-hidden">
                    <div className="blog-img position-relative overflow-hidden">
                        <img className="img-fluid" src={props.item.image} alt="" />
                        <Link className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-3" 
                        to={`/blog/${props.item.slug}`}>{props.item.category.title}</Link>
                    </div>
                    <div className="p-4">
                        <div className="d-flex mb-3">
                            <small className="me-3" style={{textTransform:'Capitalize'}}><i className="far fa-user text-primary me-2"></i>{props.item.author}</small>
                            <small><i className="far fa-calendar-alt text-primary me-2"></i>{props.item.publishedAt}</small>
                        </div>
                        <h4 className="mb-3 text-line-2">{props.item.title.substring(0,80)}</h4>
                        <p className=''>{props.item.short_description?props.item.short_description.substring(0,150):" "}</p>
                        <Link className="text-uppercase" to={`/blog/${props.item.slug}`}>Read More <i className="bi bi-arrow-right"></i></Link>
                    </div>
                </div>
            </Col>

        :props.cardsize === "listing-card"?
        
            <Col md={6} sm={12}>
                <div className="blog-item bg-light rounded overflow-hidden">
                    <div className="blog-img position-relative overflow-hidden">
                        <img className="img-fluid" src={props.item.image} alt="" />
                        <Link className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" 
                        to={`/blogs/${props.item.category.id}`}>{props.item.category.title}</Link>
                    </div>
                    <div className="p-4">
                        <div className="d-flex mb-3">
                            <small className="me-3"><i className="far fa-user text-primary me-2"></i>{props.item.author}</small>
                            <small><i className="far fa-calendar-alt text-primary me-2"></i>{props.item.publishedAt}</small>
                        </div>
                        <h4 className="mb-3"><Link to={`/blog/${props.item.slug}`}>{props.item.title}</Link></h4>
                        <p>{props.item.short_description?props.item.short_description.substring(0,150):" "}</p>
                        <Link className="text-uppercase" to={`/blog/${props.item.slug}`}>Read More <i className="bi bi-arrow-right"></i></Link>
                    </div>
                </div>
            </Col>
            
        :props.cardsize === "side-card"?

            <div className="d-flex rounded overflow-hidden mb-3">
                <img className="img-fluid  widhgt100" src={props.item.image} alt="" />
                <div className="align-items-center bg-light px-3 mb-0">
                    <Link to={`/blog/${props.item.slug}`} className="h5 text-line-2 pt-2">{props.item.title}</Link>
                    <span className="text-line-2">{props.item.short_description?props.item.short_description.substring(0,150):" "}</span>
                </div>
            </div>
        
        :""}
        </>
    )
}

export default BlogCard
import React from 'react';
import { Image, Col } from "react-bootstrap"
import { Link } from "react-router-dom"

function HomeServiceCard(props)
{
    return (
        <>
        <Col md={6} lg={4} sm={12}>
            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-imagee">
                    <img src={props.item.image} alt="" />
                </div>
                <h4 className="mb-3">{props.item.title}</h4>
                <p className="m-0">{props.item.short_desc}</p>
                <Link className="btn btn-lg btn-primary rounded" to={'/services/'+props.item.slug}>
                    <i className="bi bi-arrow-right"></i>
                </Link>
            </div>
        </Col>
        </>
    )
}

export default HomeServiceCard
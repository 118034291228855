import { Col, Container, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { getApi } from './../utils/request'
import { scrollToTop } from '../utils/function'
import ServiceCard from "../components/card/ServiceCard"

function Services() {

    const [loading, setLoading]     =   useState(true);
    const [mService, setMService]   =   useState("")
    const [data, setData]           =   useState([])
    const slug                      =   useParams().slug;

    const getData = async (slug) => {
        
        try {
            //console.log(`service/byslug?slug=${slug}`)
            const result1   = await getApi(`service/byslug?slug=${slug}`);
            const data1     = await result1.data;
            setMService(data1.data);
            if(data1.bool==true)
            {
                const serviceid = data1.data.id;
                const result2   = await getApi('subservice/byserviceid?serviceid=' + serviceid);
                const data2     = await result2.data;
                setData(data2.data);
            }

            
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        scrollToTop();
        getData(slug);
    }, [slug])

    return (
        <>
            <Container fluid className="bg-header" style={{ backgroundImage: `url(${mService.banner})`  }}>
                <Row>
                    <Col md={12} className="pt-lg-5 mt-lg-5 mb-lg-5 pb-lg-5 text-center">
                        <h1 className="display-4 text-white animated zoomIn">Services</h1>
                        <span className="h5 text-white">{mService.title}</span>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="py-5">
                <Container className="py-3">
                    <Row className="g-5">
                        <Col md={8} sm={12}>
                            <h2 className="mb-4 text-uppercase">{mService.title}</h2>
                            
                            <div className="text-justify">
                                <p dangerouslySetInnerHTML={{__html: mService.description}} />
                            </div>
                        </Col>
                        <Col md={4} sm={12}>
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="mb-0">Services</h3>
                            </div>
                            <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                                <div className="d-flex flex-wrap m-n1">
                                    {
                                        data.map((item, key) =>
                                            <Link key={key} to={`/service/${item.slug}`} className="btn btn-light m-1">{item.title}</Link>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                                <img src="/images/blog-1.jpg" alt="" className="img-fluid rounded" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Services
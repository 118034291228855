import axios from "axios";
import { API_URL, APP_NAME } from "./config";

export const getUrl = (endpoint) => {
    return API_URL + endpoint;
}

export const commonHeader = {
    "Content-Type": "application/json",
}

export const getApi = async (endpoint) => {
    return await axios.get(getUrl(endpoint), {
        headers: { ...commonHeader },
    });
}

export const postApi = async (endpoint, data) => {
    return await axios.post(getUrl(endpoint), data,  {
        headers: { ...commonHeader },
    });
};


import React, { useEffect, useState } from "react"
import { Carousel, Col, Container, Row } from "react-bootstrap"
import OwlCarousel from "react-owl-carousel"
import { Link } from "react-router-dom"

import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { getApi } from './../utils/request'

//import BannerCard from "./../components/card/BannerCard";
import HomeServiceCard from "./../components/card/HomeServiceCard";
import TestimonialCard from "./../components/card/TestimonialCard";
import BlogCard from "./../components/card/BlogCard";
import ClientCard from "./../components/card/ClientCard";

import { SeoTitle } from './../utils/function'
import { scrollToTop } from '../utils/function'

function Home(props) {
    
    const [bannerdata, setBannerdata]               = useState([])
    const [choosedata, setChoosedata]               = useState([])
    const [testimonialdata, setTestimonialdata]     = useState([])
    const [blogdata, setBlogdata]                   = useState([])
    const [clientdata, setClientdata]               = useState([])   

    const getSeo = async () => {
        try {
            const result6   = await getApi('default/bytype?type=Home');
            const data6     = await result6.data;
            SeoTitle(data6.data.title)
        }
        catch (error) {
            SeoTitle('Fincon Consultancy')
        }
    }
    
    const getData = async () => {
        try {
            const result1 = await getApi('banner/all');
            const data1 = await result1.data;
            setBannerdata(data1.data);

            const result2 = await getApi('choose/all');
            const data2 = await result2.data;
            setChoosedata(data2.data);

            const result3 = await getApi('testimonial/all');
            const data3 = await result3.data;
            setTestimonialdata(data3.data);

            const result4 = await getApi('blog/all?start=0&limit=3');
            const data4 = await result4.data;
            setBlogdata(data4.data);
           
            const result5 = await getApi('client/all');
            const data5 = await result5.data;
            setClientdata(data5.data);

            console.log(data5.data)
        }
        catch (error) {
        }
    }

    useEffect(() => {
        scrollToTop();
        getSeo();
        getData();
    }, [])
    
    return (
        <>
            {/* Banner */}
            <Carousel fluid="true">
                {
                    bannerdata.map((item, key) =>
                        <Carousel.Item key={key}>
                            <img className="d-block w-100" src={`${item.banner}`} alt="slide" />
                            <Carousel.Caption>
                                <h3>{item.title}</h3>
                                <p>{item.short_desc}</p>
                                <Link to="/about/about-us" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">About Us</Link>
                                <Link to="/contact" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</Link>
                            </Carousel.Caption>
                        </Carousel.Item> 
                    )
                }
            </Carousel>

            {/* About Us */}
            <Container fluid className="py-3 wow fadeInUp" >
                <Container className="py-5">
                    <Row className="g-5">
                        <Col md={7} sm={12}>
                            <div className="section-title position-relative pb-3 mb-3">
                                <h5 className="text-primary text-uppercase">About Us</h5>
                                <h1 className="mb-0 text-uppercase">Fincon Consultancy</h1>
                            </div>
                            <span className="mb-4 text-justify" dangerouslySetInnerHTML={{__html: props.data.contactData.home_about_content}} />
                            
                            <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                                <div className="bg-primary d-flex align-items-center justify-content-center rounded widhgt60">
                                    <i className="fa fa-phone-alt text-white"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="mb-2">Call to ask any question</h5>
                                    <h4 className="text-primary mb-0">{props.data.contactData.contact}</h4>
                                </div>
                            </div>
                        </Col>
                        <Col md={5} sm={12}>
                            <div className="position-relative">
                                <img className="w-100 h-100 rounded wow zoomIn" src={props.data.contactData.home_about_image} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/*  Services */}
            <Container fluid className="wow">
                <Container className="py-3">
                    <div className="section-title text-center position-relative pb-3 mb-4 mx-auto">
                        <h5 className="text-primary text-uppercase">Our Services</h5>
                        <h1 className="mb-0">Your One Stop Solution</h1>
                    </div>
                    <Row className="g-5">
                        {
                            props.data.serviceData.slice(0,5).map((item, key) =>
                                <HomeServiceCard  key={key} item={item} />
                            )
                        }
                        <Col md={6} lg={4} sm={12}>
                            <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                                <h3 className="text-white mb-3">Call Us Enquiry</h3>
                                <p className="text-white mb-3">You can call us on below number for any enquiry and information.</p>
                                <h2 className="text-white mb-0">{props.data.contactData.contact}</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* Choose */}
            <Container fluid className="wow py-4">
                <Container className="py-3">
                    <div className="section-title text-center position-relative pb-3 mb-4 mx-auto">
                        <h5 className="text-primary text-uppercase">Why Choose Us</h5>
                        <h1 className="mb-0">We Are Best in Industry</h1>
                    </div>
                    <Row className="g-5">
                        <Col md={4}>
                            <Row className="g-4">
                                {
                                    choosedata.slice(0,1).map((item, key) =>
                                        <Col key={key} md={12}>
                                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                                                <i className="fa fa-cubes text-white"></i>
                                            </div>
                                            <h4>{item.title}</h4>
                                            <p className="mb-0">{item.description}</p>
                                        </Col>
                                    )
                                }

                                {
                                    choosedata.slice(1,2).map((item, key) =>
                                        <Col key={key} md={12}>
                                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                                                <i className="fa fa-award text-white"></i>
                                            </div>
                                            <h4>{item.title}</h4>
                                            <p className="mb-0">{item.description}</p>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>
                        <Col md={4}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" src="/images/feature.jpg" alt="" />
                            </div>
                        </Col>
                        <Col md={4}>
                            <Row className="g-4">
                                {
                                    choosedata.slice(2,3).map((item, key) =>
                                        <Col key={key} md={12}>
                                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                                                <i className="fa fa-users-cog text-white"></i>
                                            </div>
                                            <h4>{item.title}</h4>
                                            <p className="mb-0">{item.description}</p>
                                        </Col>
                                    )
                                }

                                {
                                    choosedata.slice(3,4).map((item, key) =>
                                        <Col key={key} md={12}>
                                            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                                                <i className="fa fa-phone-alt text-white"></i>
                                            </div>
                                            <h4>{item.title}</h4>
                                            <p className="mb-0">{item.description}</p>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* Testimonial */}
            <Container fluid className="wow py-3">
                <Container className="py-3">
                    <div className="section-title text-center position-relative pb-3 mb-1 mx-auto">
                        <h5 className="text-primary text-uppercase">Testimonial</h5>
                        <h1 className="mb-0">What Our Clients Say</h1>
                    </div>

                    <OwlCarousel className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.6s">
                        {
                            testimonialdata.map((item, key) =>
                                <TestimonialCard key={key} item={item} />
                            )
                        }
                    </OwlCarousel>
                </Container>
            </Container>

            {/*  Blogs */}
            <Container fluid className="wow py-4">
                <Container className="py-3">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
                        <h5 className="text-primary text-uppercase">Latest Blog</h5>
                        <h1 className="mb-0">Read The Latest Articles</h1>
                    </div>
                    <Row className="g-5">
                        {
                            blogdata.map((item, key) =>
                                <BlogCard key={key} item={item} cardsize='home-card' />
                            )
                        }
                    </Row>
                </Container>
            </Container>

            {/*  Client */}           
            <Container fluid className="wow py-3">
                <Container className="py-3 mb-3">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
                        <h5 className="text-primary text-uppercase">Our Clients</h5>
                        <h1 className="mb-0">List is Infinite</h1>
                    </div>
                    <Row className="g-5">
                        {
                            clientdata.slice(0,6).map((item, key) =>
                                <Col md={2} sm={6}>
                                    <img  key={key} src={item.image} alt={item.name} />
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Home
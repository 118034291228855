import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

function Footer(props) {
    return (
        <>
            <Container fluid className="bg-dark text-light mt-2">
                <Container>
                    <Row className="gx-5">
                        <Col md={4} sm={6} className="footer-about">
                            <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                                <Link to="/" className="navbar-brand">
                                    <img src="/images/fincon.png" style={{width:'50%'}} alt="Fincon Consultancy" />
                                </Link>
                                <p className="mt-3 mb-4">{props.data.contactData.footer_about_content}</p>
                                <Link to="/">www.finconconsultancy.com</Link>
                            </div>
                        </Col>
                        <Col md={8} sm={6}>
                            <Row className="gx-5">
                                <Col md={4} sm={12} className="pt-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Get In Touch</h3>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-geo-alt text-primary me-2"></i>
                                        <p className="mb-0">{props.data.contactData.address}</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-envelope-open text-primary me-2"></i>
                                        <p className="mb-0">{props.data.contactData.email}</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-telephone text-primary me-2"></i>
                                        <p className="mb-0">{props.data.contactData.contact}</p>
                                    </div>
                                    <div className="d-flex mt-4">
                                        <Link className="btn btn-primary btn-square me-2" to={props.data.contactData.social_twitter}><i className="fab fa-twitter fw-normal"></i></Link>
                                        <Link className="btn btn-primary btn-square me-2" to={props.data.contactData.social_facebook}><i className="fab fa-facebook-f fw-normal"></i></Link>
                                        <Link className="btn btn-primary btn-square me-2" to={props.data.contactData.social_linkedin}><i className="fab fa-linkedin-in fw-normal"></i></Link>
                                        <Link className="btn btn-primary btn-square" to={props.data.contactData.social_insta}><i className="fab fa-instagram fw-normal"></i></Link>
                                    </div>
                                </Col>
                                <Col md={4} sm={12} className="pt-0 pt-lg-5 mb-5">
                                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="text-light mb-0">Quick Links</h3>
                            </div>
                            <div className="link-animated d-flex flex-column justify-content-start">
                                <Link className="text-light mb-2" to="/"><i className="bi bi-arrow-right text-primary me-2"></i>Home</Link>
                                <Link className="text-light mb-2" to="/testimonials"><i className="bi bi-arrow-right text-primary me-2"></i>Testimonials</Link>
                                <Link className="text-light mb-2" to="/clients"><i className="bi bi-arrow-right text-primary me-2"></i>Clients</Link>
                                <Link className="text-light mb-2" to="/blogs"><i className="bi bi-arrow-right text-primary me-2"></i>Latest Blog</Link>
                                <Link className="text-light" to='/contact'><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</Link>
                            </div>
                                </Col>
                                <Col md={4} sm={12} className="pt-0 pt-lg-5 mb-5">
                                <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="text-light mb-0">Services Links</h3>
                            </div>
                            <div className="link-animated d-flex flex-column justify-content-start">
                                {
                                    props.data.serviceData.slice(0,5).map((itemm1,keyy1)=>
                                        <Link key={keyy1} className="text-light mb-2" to={'/services/'+itemm1.slug}><i className="bi bi-arrow-right text-primary me-2"></i>{itemm1.title}</Link>
                                    )
                                }
                            </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Footer
import React from 'react';
import { Link } from "react-router-dom"

function CareerCard(props)
{
    return(
        <div className="s-12 m-6 l-6 margin-bottom-30">                                                                                                                                                 
            <div className="margin-top">                          
                <h4 className="text-strong">{props.item.title}</h4>
                <div dangerouslySetInnerHTML={{__html: props.item.description}} />
                <p>Send your updated CV at <Link to="mailto:hr@finconconsultancy.com" target="_blank" className="button" style={{background:'#039', borderRadius:'100px'}}>hr@finconconsultancy.com</Link></p>		                                                                                                                                                                               
            </div>
        </div>
    )
}

export default CareerCard